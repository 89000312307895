import React, { useState } from "react";
import Login from "./Login"; // Import the Login component
import TestGenerator from "./TestGenerator"; // Import the TestGenerator component

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Handle login success
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <div>
      {/* Show Login component if not logged in, else show TestGenerator */}
      {isLoggedIn ? <TestGenerator /> : <Login onLogin={handleLogin} />}
    </div>
  );
};

export default App;
